'use client'

import { useCallback, useMemo } from "react";
import { ProfileFormValues } from "@/schemas/profile.schema";
import { Session, User } from "next-auth";
import { useSession } from "next-auth/react";

type UserConfig = Pick<Session, "user" | "config" | "signed_in"> & { updateSessionUser: (updateObject: Partial<ProfileFormValues>) => void };

export const useUserSession = (): UserConfig => {
  const { data, update } = useSession();
  
  const updateSessionUser = useCallback(async (updateObject: Partial<ProfileFormValues>) => {
    if (data?.user) {

      const updatedUser: User = { 
        ...data.user, 
        ...updateObject 
      }

      const newSessionAuth: Session = { 
        ...data, 
        user: updatedUser 
      }
      
      await update(newSessionAuth)
    }
  }, [data, update])
  
  const userConfig = useMemo(() => ({
    user: data?.user || ({} as User),
    config: data?.config || ({} as any),
    signed_in: data?.signed_in || '' as any,
    updateSessionUser
  }), [data, update])

  return userConfig;
}