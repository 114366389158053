import { BadgeProps } from "@/interfaces/IBadge";
import CancelIcon from '@mui/icons-material/Cancel';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PersonIcon from '@mui/icons-material/Person';
import CallEndIcon from '@mui/icons-material/CallEnd';

export enum SessionStatusEnum {
  ACTIVE = "active",
  FINISHED = "finished",
  PENDING_HUMAN = "pending_human",
  HUMAN_INLOOP = "human_inloop",
  RESOLVED = "resolved",
}

export enum QuestionStatusEnum {
  PENDING = "pending",
  NOTIFIED = "notified",
  ANSWERED = "answered",
  RESOLVED = "resolved"
}

export enum QuestionArgsMessageTypeEnum {
  TEMPLATE = "template",
}

export const statusName = { 
  [SessionStatusEnum.ACTIVE]: 'Aberto',
  [SessionStatusEnum.FINISHED]: 'Encerrado' 
}

export const StatusBadge: BadgeProps[] = [
  {
    slug: '',
    title: 'Nenhum',
    icon: <CancelIcon className="text-sm" />,
    className: 'bg-danger border-dangerDarker text-dangerDarker dark:bg-slate-700 dark:border-slate-900 dark:text-slate-200',
  },
  {
    slug: SessionStatusEnum.ACTIVE,
    title: 'Aberto',
    icon: <LockOpenIcon className="text-sm" />,
    className: 'bg-success border-successBorder text-successDarker dark:bg-slate-700 dark:border-slate-900 dark:text-slate-200',
  },
  {
    slug: SessionStatusEnum.FINISHED,
    title: 'Encerrado',
    icon: <LockIcon className="text-sm" />,
    className: 'bg-zinc-200 border-zinc-300 text-zinc-500 dark:bg-slate-700 dark:border-slate-900 dark:text-slate-200',
  },
  {
    slug: SessionStatusEnum.PENDING_HUMAN,
    title: 'Na Fila',
    icon: <PersonIcon className="text-sm" />,
    className: 'bg-orange-200 border-orange-300 text-orange-700 dark:bg-slate-700 dark:border-slate-900 dark:text-slate-200',
  },
  {
    slug: SessionStatusEnum.HUMAN_INLOOP,
    title: 'Em atendimento',
    icon: <PersonIcon className="text-sm" />,
    className: 'bg-blue-200 border-blue-300 text-blue-500 dark:bg-slate-700 dark:border-slate-900 dark:text-slate-200',
  },
  {
    slug: SessionStatusEnum.RESOLVED,
    title: 'Resolvido',
    icon: <CallEndIcon className="text-sm" />,
    className: 'bg-zinc-500 border-zinc-500 text-zinc-100 dark:bg-slate-700 dark:border-slate-900 dark:text-slate-200',
  },
];