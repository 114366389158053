import { HeadSetIcon } from "@/components/base/Icons/HeadSet";
import HomeIcon from '@mui/icons-material/Home';
import { BrainIcon } from "@/components/base/Icons/Brain";
import { TicketIcon } from '@/components/base/Icons/Ticket';
import { DashBoardIcon } from '@/components/base/Icons/DashBoard';
import { DatabaseIcon } from '@/components/base/Icons/Database';
import { AgentAiIcon } from '@/components/base/Icons/AgentAI';
import InboxIcon from '@mui/icons-material/Inbox';
import SensorsIcon from '@mui/icons-material/Sensors';
import UploadIcon from '@mui/icons-material/Upload';
import ArticleIcon from '@mui/icons-material/Article';
import { TestingToolIcon } from "@/components/base/Icons/TestingTool";
import Box from "@mui/material/Box";

export const getMenuIcon = (iconName: string, iconClass?: string, iconHexa?: string) => {
  switch (iconName) {
    case 'home':
      return <HomeIcon className={iconClass || `text-xl text-white`} />;
    case 'live':
      return <SensorsIcon className={iconClass || `text-2xl text-white`} />;
    case 'inbox':
      return <InboxIcon className={iconClass || `text-xl text-white`} />;
    case 'headset':
      return <HeadSetIcon className={iconClass || `text-xl text-white`} />;
    case 'brain':
      return <BrainIcon className={iconClass || `text-xl text-white`} />;
    case 'ticket':
      return <TicketIcon className={iconClass || `text-xl text-white`} />;
    case 'dashboard':
      return <DashBoardIcon className={iconClass || `text-xl text-white`} />;
    case 'agents':
      return <AgentAiIcon className={iconClass || `text-xl text-white`} />;
    case 'testingtool':
      return <TestingToolIcon className={iconClass || `text-xl text-white`} />;
    case 'database':
      return <DatabaseIcon color={iconHexa || `#FFF`} className={iconClass || `text-lg text-white`} />;
    case 'upload':
      return <UploadIcon className={iconClass || `text-white`} />;
    case 'article':
      return <ArticleIcon className={iconClass || `text-white`} />;
    default:
      return null;
  }
};

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: string | number;
  value: string | number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      className="p-0"
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}